import { Button } from 'components/Button';
import { AutocompleteInput, Input } from 'components/Form';
import { CheckboxInput } from 'components/Form/Input/CheckboxInput';

import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { FaDollarSign, FaPlus } from 'react-icons/fa6';
import { HiTrash } from 'react-icons/hi';

import styles from 'styles/Settings.module.css';

const RateItem = ({ name, priorityValue, checked, onChange }) => {
    const {
        register,
        watch,
        formState: { errors },
    } = useForm();

    return (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <select
                disabled={!checked}
                style={{ width: '60px' }}
                defaultValue={priorityValue} // Set default value here
            >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
            </select>

            <CheckboxInput
                name={name}
                value={name}
                label={`Use ${name} Rate`}
                checked={checked}
                onChange={onChange}
            />

            {checked && (
                <Input
                    type='text'
                    placeholder='Margin (%)'
                    label='margin'
                    register={register}
                    watch={watch}
                    errors={errors}
                    size='sm'
                    narrow
                />
            )}
        </div>
    );
};

const ContractedLaneRow = ({
    register,
    watch,
    setValue,
    errors,
    index,
    remove,
}) => {
    return (
        <div style={{ display: 'flex', gap: '8px' }}>
            <AutocompleteInput
                id={`contracted_lanes.${index}.origin`}
                value={watch(`contracted_lanes.${index}.origin`) || ''}
                setValue={setValue}
                label='Origin'
                register={register}
                errors={errors}
                watch={watch}
                placeholder='Origin'
                size='sm'
                formStyling
                hideErrorMessage
                hideEndIcon
            />

            <AutocompleteInput
                id={`contracted_lanes.${index}.destination`}
                value={watch(`contracted_lanes.${index}.destination`) || ''}
                setValue={setValue}
                label='Destination'
                register={register}
                errors={errors}
                watch={watch}
                placeholder='Destination'
                size='sm'
                formStyling
                hideErrorMessage
                hideEndIcon
            />

            <Input
                type='text'
                size='sm'
                label={`contracted_lanes.${index}.rate`}
                placeholder='Total Rate'
                register={register}
                errors={errors}
                watch={watch}
                startIcon={<FaDollarSign />}
            />

            <Button
                uitype='icon'
                radius='round'
                color='secondary-dark'
                onClick={(event) => {
                    event.preventDefault();
                    remove(index);
                }}
            >
                <HiTrash title='Delete Lane' />
            </Button>
        </div>
    );
};

const CustomRatesCard = ({ onSubmit }) => {
    const {
        register,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm({});

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'contracted_lanes',
    });

    const [selectedRates, setSelectedRates] = useState({});
    const [submitting, setSubmitting] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => append({}), []);

    return (
        <div>
            <h2 className={styles.heading}>Auto Quote Rate Setting</h2>
            <form
                className={styles.form}
                onSubmit={async (event) => {
                    event.preventDefault();
                    setSubmitting(true);
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    setSubmitting(false);
                    onSubmit();
                }}
            >
                <RateItem
                    name='Greenscreens'
                    checked={selectedRates.greenscreens}
                    onChange={() =>
                        setSelectedRates((prev) => ({
                            ...prev,
                            greenscreens: !prev.greenscreens,
                        }))
                    }
                />
                <RateItem
                    name='TruckStop'
                    checked={selectedRates.dat}
                    onChange={() =>
                        setSelectedRates((prev) => ({
                            ...prev,
                            dat: !prev.dat,
                        }))
                    }
                />
                <RateItem
                    name='Company Historical'
                    checked={selectedRates.historical}
                    onChange={() =>
                        setSelectedRates((prev) => ({
                            ...prev,
                            historical: !prev.historical,
                        }))
                    }
                />
                <RateItem
                    name='Custom'
                    checked={selectedRates.custom}
                    onChange={() =>
                        setSelectedRates((prev) => ({
                            ...prev,
                            custom: !prev.custom,
                        }))
                    }
                />

                {selectedRates.custom && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <p
                            style={{
                                fontSize: '1.2rem',
                                lineHeight: '1.5rem',
                                fontWeight: '600',
                                borderBottom: '1px solid black',
                                color: 'var(--secondary-black)',
                                width: 'fit-content',
                                margin: 0,
                            }}
                        >
                            Base rates ($/mi)
                        </p>
                        <table style={{ borderSpacing: '8px' }}>
                            <thead style={{ color: 'var(--primary-700)' }}>
                                <tr>
                                    <th></th>
                                    <th>&lt;200 miles</th>
                                    <th>201-500 miles</th>
                                    <th>501-1000 miles</th>
                                    <th>1001-1500 miles</th>
                                    <th>1500+ miles</th>
                                </tr>
                            </thead>
                            <tbody>
                                {['Van', 'Refrigerated', 'Flatbed'].map(
                                    (vehicle) => (
                                        <tr key={vehicle}>
                                            <th
                                                style={{
                                                    textAlign: 'right',
                                                    color: 'var(--primary-700)',
                                                }}
                                            >
                                                {vehicle}
                                            </th>
                                            {[0, 200, 500, 1000, 1500].map(
                                                (distance) => (
                                                    <td key={distance}>
                                                        <Input
                                                            type='text'
                                                            size='sm'
                                                            label={`${vehicle}-${distance}`}
                                                            register={register}
                                                            errors={errors}
                                                            watch={watch}
                                                            placeholder='$/mi'
                                                        />
                                                    </td>
                                                )
                                            )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>

                        <p
                            style={{
                                fontSize: '1.2rem',
                                lineHeight: '1.5rem',
                                fontWeight: '600',
                                borderBottom: '1px solid black',
                                color: 'var(--secondary-black)',
                                width: 'fit-content',
                                margin: 0,
                            }}
                        >
                            Contracted lanes
                        </p>

                        <div
                            style={{
                                padding: '8px 8px 0 0',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '12px',
                            }}
                        >
                            {fields.map((item, index) => (
                                <ContractedLaneRow
                                    key={item.id}
                                    register={register}
                                    watch={watch}
                                    setValue={setValue}
                                    errors={errors}
                                    index={index}
                                    remove={remove}
                                />
                            ))}
                        </div>

                        <Button
                            uitype='cta'
                            color='primary-dark'
                            size='sm'
                            onClick={(event) => {
                                event.preventDefault();
                                append({});
                            }}
                        >
                            <FaPlus /> Add more
                        </Button>
                    </div>
                )}

                <Button
                    uitype='cta'
                    color='primary-dark'
                    size='lg'
                    fullwidth
                    isLoading={submitting}
                >
                    Save Rates
                </Button>
            </form>
        </div>
    );
};

export { CustomRatesCard };
