export const getUsers = (data) => {
  const uniqueUsers = new Set();
  for (const obj of data.datasets) {
      if (obj.data.some((e) => e)) {
          uniqueUsers.add(obj.label);
      }
  }
  return Array.from(uniqueUsers);
};

const formatDate = (date, length) => {
  switch (length) {
      case 'long':
          return date.toLocaleDateString('en-US', {
              weekday: undefined,
              year: 'numeric',
              month: 'short',
              day: 'numeric',
          });
      case 'short':
          return date.toLocaleDateString('en-US', {
              weekday: undefined,
              year: undefined,
              month: 'short',
              day: 'numeric',
          });
      default:
          return date.toLocaleDateString('en-US', {
              weekday: undefined,
              year: 'numeric',
              month: 'short',
              day: 'numeric',
          });
  }
};

/* Generates a random number within given range
* @param {number} min — lowest possible number in range
* @param {number} max — highest possible number in range
* @return {number} random number between min and max
*/
export const getRandNum = (min, max) => {
  return min + Math.random() * (max - min);
};

/* Converts datestring into US local date format
* @param {string} datestring ex: 2022-08-03T05:00:00.000Z
* @return {string} ex: August 9, 2022
*/
export const formatDateString = (datestring) => {
  const date = new Date(datestring);
  return date.toLocaleDateString('en-US', {
      weekday: undefined,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
  });
};

const incrementDate = (date) => {
  const currDate = new Date(date);
  const forward = new Date(currDate.setDate(currDate.getDate() + 1));
  return formatDate(forward);
};

export const getBackdate = (days, dateFormat) => {
  const now = new Date();
  const backdate = new Date(now.setDate(now.getDate() - days));
  switch (dateFormat) {
      case 'long':
          return formatDate(backdate, 'long');
      case 'short':
          return formatDate(backdate, 'short');
      default:
          return formatDate(backdate);
  }
};

export const getFilteredByDate = (option, data) => {
  if (!data) {
      return undefined;
  }

  let days = 7;
  switch (option) {
      case 'Last 2 Weeks':
          days = 14;
          break;
      case 'Last 15 Days':
          days = 15;
          break;
      case 'Last 3 Weeks':
          days = 21;
          break;
      case 'Last Month':
          days = 30;
          break;
      default:
          break;
  }

  if (option.includes('Custom')) {
      const dates = option.replace('Custom: ', '').split(' - ');
      let startDate = dates[0];
      let endDate = dates[1];
      if (endDate === 'Today') {
          endDate = new Date();
          if (startDate.length === 5) {
              startDate += String(new Date().getFullYear());
          }
      } else {
          if (startDate.length === 5) {
              startDate += '/' + endDate.slice(-4);
          }
          endDate = new Date(endDate);
      }
      startDate = new Date(startDate);
      return getFilteredByCustomDate(startDate, endDate, data);
  }

  let backdate = getBackdate(days);

  let filtered = [];
  for (let i = 0; i < data.length; i++) {
      const dates = data[i].labels;
      if (dates.length === 0) {
          filtered.push({ labels: [], datasets: [] });
          continue;
      }
      let dateIdx = dates.indexOf(backdate); //used to locate earliest date in array within the selected timeframe
      //sequentially iterates through dates starting from selected timeframe to current date. Ejects when soonest date is identified
      while (dateIdx === -1 && new Date(backdate) <= new Date()) {
          backdate = incrementDate(backdate);
          dateIdx = dates.indexOf(backdate);
      }

      let filteredData = { datasets: [] };

      filteredData.labels = data[i].labels.slice(dateIdx); //removes all dates after the dateIdx
      for (let j = 0; j < data[i].datasets.length; j++) {
          filteredData.datasets.push({ data: [] });
          filteredData.datasets[j].data =
              data[i].datasets[j].data.slice(dateIdx);
          filteredData.datasets[j].borderColor =
              data[i].datasets[j].borderColor;
          filteredData.datasets[j].backgroundColor =
              data[i].datasets[j].backgroundColor;
          if (data[i].datasets[j].fill) {
              filteredData.datasets[j].fill = data[i].datasets[j].fill;
          }
          if (data[i].datasets[j].label) {
              filteredData.datasets[j].label = data[i].datasets[j].label;
          }
      }
      backdate = getBackdate(days); //needed otherwise straight graph will always show just one day
      filtered.push(filteredData);
  }

  return filtered;
};

export const getFilteredByCustomDate = (startDate, endDate, data) => {
  let start = formatDate(startDate);
  let end = formatDate(endDate);

  let filtered = [];
  for (let i = 0; i < data.length; i++) {
      const dates = data[i].labels;
      let startIdx = dates.indexOf(start);
      while (startIdx === -1) {
          if (new Date(start) > new Date(dates[dates.length - 1])) {
              startIdx = dates.length - 1;
          } else {
              start = incrementDate(start);
              startIdx = dates.indexOf(start);
          }
      }
      let endIdx = dates.indexOf(end);
      while (endIdx === -1 && new Date(end) <= new Date()) {
          if (new Date(end) > new Date(dates[dates.length - 1])) {
              endIdx = dates.length - 1;
          } else {
              end = incrementDate(end);
              endIdx = dates.indexOf(end);
          }
      }

      let filteredData = { datasets: [] };

      filteredData.labels = data[i].labels.slice(startIdx, endIdx);
      for (let j = 0; j < data[i].datasets.length; j++) {
          filteredData.datasets.push({ data: [] });
          filteredData.datasets[j].data = data[i].datasets[j].data.slice(
              startIdx,
              endIdx
          );
          filteredData.datasets[j].borderColor =
              data[i].datasets[j].borderColor;
          filteredData.datasets[j].backgroundColor =
              data[i].datasets[j].backgroundColor;
          if (data[i].datasets[j].fill) {
              filteredData.datasets[j].fill = data[i].datasets[j].fill;
          }
          if (data[i].datasets[j].label) {
              filteredData.datasets[j].label = data[i].datasets[j].label;
          }
      }

      filtered.push(filteredData);
  }

  return filtered;
};

const formatRates = (market_rates, company_rates, company) => {
  let formatted = {};
  const marketColor = '#f7b48a';
  const companyColor = '#03989E';
  let market_dataset = {
      label: 'Market',
      borderColor: marketColor + 'C4',
      backgroundColor: marketColor,
      data: [],
  };
  let company_dataset = {
      label: company,
      borderColor: companyColor + 'A4',
      backgroundColor: companyColor,
      data: [],
  };
  let uniqueDates = new Set();
  const marketDates = [];
  const companyDates = [];

  // get dates
  for (const obj of market_rates) {
      const date = formatDate(new Date(obj.date));
      uniqueDates.add(date);
      marketDates.push(date);
  }
  if (company_rates) {
      for (const obj of company_rates) {
          const date = formatDate(new Date(obj.date));
          uniqueDates.add(date);
          companyDates.push(date);
      }
  }

  // assign dates as labels
  uniqueDates = Array.from(uniqueDates);
  formatted.labels = uniqueDates.sort((a, b) => {
      return new Date(a) - new Date(b);
  });

  for (const item of uniqueDates) {
      const market_idx = marketDates.indexOf(item);
      const company_idx = companyDates.indexOf(item);
      market_dataset.data.push(
          market_rates[market_idx]
              ? market_rates[market_idx].market_rate
              : null
      );
      company_dataset.data.push(
          company_rates && company_rates[company_idx]
              ? company_rates[company_idx].market_rate
              : null
      );
  }
  company_dataset.fill = {
      target: '-1',
      below: marketColor + '48',
      above: companyColor + '32',
  };
  formatted.datasets = [market_dataset, company_dataset];

  return { trend: formatted };
};

export const formatChartData = (data, company) => {
  return formatRates(
      data.map(() => {
          return { date: undefined, market_rate: undefined };
      }),
      data.map((obj) => {
          return {
              date: obj.date,
              market_rate: obj.avg_booked_revenue_customer,
          };
      }),
      company !== 'Demo' ? company : 'Your Company'
  ).trend;
};

export const formatGridData = (...data) => {
    return data.map((obj) => {
        // Parse and validate necessary values
        let distancemiles = parseInt(obj.distancemiles);
        if (isNaN(distancemiles)) distancemiles = 0;

        let revenue_linehaul = parseFloat(obj.revenue_linehaul);
        if (isNaN(revenue_linehaul)) revenue_linehaul = 0;

        let cost_linehaul = parseFloat(obj.cost_linehaul);
        if (isNaN(cost_linehaul)) cost_linehaul = 0;

        // Calculate rate per mile with proper checks
        let rate_per_mile;

        if (distancemiles !== 0) {
            rate_per_mile = revenue_linehaul / distancemiles;
        } else if (obj.rate_per_mile) {
            rate_per_mile = parseFloat(obj.rate_per_mile);
            if (isNaN(rate_per_mile)) rate_per_mile = 0;
        } else {
            rate_per_mile = 0;
        }

        // Ensure rate_per_mile is valid
        if (!isFinite(rate_per_mile) || isNaN(rate_per_mile)) {
            rate_per_mile = 0;
        }

        return {
            ...obj,
            uorderpk: obj.networkorderid ?? obj.uorderpk,
            bookeddate: new Date(obj.bookeddate).toLocaleDateString(),
            pickup_location: `${obj.firstpickup_city}, ${obj.firstpickup_statecode}`,
            dropoff_location: `${obj.lastdelivery_city}, ${obj.lastdelivery_statecode}`,
            firstpickup_datetime: new Date(
                obj.firstpickup_datetime
            ).toLocaleDateString(),
            lastdelivery_datetime: new Date(
                obj.lastdelivery_datetime
            ).toLocaleDateString(),
            revenue_linehaul: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
            }).format(revenue_linehaul),
            cost_linehaul: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
            }).format(cost_linehaul),
            rate_per_mile: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(rate_per_mile),
            distancemiles: distancemiles,
        };
    });
};