import { Button } from 'components/Button';
import { Input } from 'components/Form';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import styles from 'styles/Settings.module.css';

const CustomCustomersCard = ({ onSubmit }) => {
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [submitting, setSubmitting] = useState(false);

    const handleFormSubmit = async (data) => {
        setSubmitting(true);
        // Handle form submission logic here
        // For demonstration, we'll simulate a delay
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setSubmitting(false);
        if (onSubmit) {
            onSubmit(data);
        }
    };

    return (
        <div>
            <h2 className={styles.heading}>Customer Information</h2>
            <form
                className={styles.form}
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <Input
                    type='text'
                    label='Customer Code'
                    placeholder='Enter Customer Code'
                    name='customerCode'
                    register={register}
                    errors={errors}
                    watch={watch}
                    size='lg'
                />
                <Input
                    type='text'
                    label='Revenue Code'
                    placeholder='Enter Revenue Code'
                    name='revenueCode'
                    register={register}
                    errors={errors}
                    watch={watch}
                    size='lg'
                />
                <Button
                    uitype='cta'
                    color='primary-dark'
                    size='lg'
                    fullwidth
                    isLoading={submitting}
                >
                    Save
                </Button>
            </form>
        </div>
    );
};

export { CustomCustomersCard };
